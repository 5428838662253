import * as React from "react";
import { useSubmissionList } from "../../../../hooks/api/submission/useSubmissionList";
import { BreadCrumb, IPage } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../../atoms/Loading/Loading";
import { SubmissionsList } from "./SubmissionsList";

export const MySubmissions: React.FC = () => {
    const applications = useSubmissionList();
    const pages: Array<IPage> = [{ name: "Submissions" }];

    if (applications.isLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>
            <SubmissionsList />
            <div className="text-center border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="mx-auto size-12 text-gray-400">
                    <path
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        strokeWidth={2}
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">DS160 Submissions</h3>
                <p className="mt-1 text-sm text-gray-500">Create a DS160 Submission from an existing DS160 form.</p>
            </div>
        </>
    );
};
