import React, { createContext, useContext } from "react";

interface ICheckoutContextValue {
    readonly productCode: string;
}

const FormNavContext = createContext<ICheckoutContextValue | undefined>(undefined);

export const useCheckout = () => {
    const context = useContext(FormNavContext);
    if (context === undefined) {
        throw new Error("useFormNav must be used within a FormNavProvider");
    }
    return context;
};

interface ICheckoutProviderProps {
    readonly productCode: string;
    readonly children: React.ReactNode;
}

export const CheckoutProvider: React.FC<ICheckoutProviderProps> = ({ productCode, children }) => {
    const value: ICheckoutContextValue = { productCode };
    return <FormNavContext.Provider value={value}>{children}</FormNavContext.Provider>;
};
