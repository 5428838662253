import * as React from "react";

export const RemarkH1: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h1 className="text-3xl font-bold tracking-tight text-gray-900 mt-4 mb-4">{children}</h1>;
};

export const RemarkH2: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h2 className="text-2xl font-bold tracking-tight text-gray-800 mt-3 mb-3">{children}</h2>;
};

export const RemarkH3: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h3 className="text-xl font-bold tracking-tight text-gray-700 mt-3 mb-3">{children}</h3>;
};

export const RemarkH4: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h4 className="text-lg font-bold tracking-tight text-gray-600 mt-2 mb-2">{children}</h4>;
};

export const RemarkH5: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h5 className="text-base font-bold tracking-tight text-gray-500 mt-1 mb-1">{children}</h5>;
};

export const RemarkH6: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <h6 className="text-sm font-bold tracking-tight text-gray-400 mt-1 mb-1">{children}</h6>;
};

export const RemarkP: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <p className="text-base leading-7 text-gray-600 inline mt-2 mb-2">{children}</p>;
};

export const RemarkBlockquote: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-700">{children}</blockquote>;
};

export const RemarkCode: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <code className="bg-gray-100 text-sm font-mono text-gray-800 px-1 py-0.5 rounded">{children}</code>;
};

export const RemarkPre: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm font-mono text-gray-800">{children}</pre>;
};

export const RemarkUl: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <ul className="list-disc list-inside pl-4">{children}</ul>;
};

export const RemarkOl: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <ol className="list-decimal list-inside pl-4">{children}</ol>;
};

export const RemarkLi: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <li className="mb-1 text-gray-600 list-item">{children}</li>;
};

export const RemarkA: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, ...props }) => {
    return (
        <a className="text-blue-600 hover:underline" {...props}>
            {children}
        </a>
    );
};

export const RemarkImg: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
    return <img className="max-w-full h-auto" {...props} />;
};

export const RemarkHr: React.FC = () => {
    return <hr className="border-t border-gray-300 my-4" />;
};

export const RemarkTable: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <table className="w-full border-collapse border border-gray-300">{children}</table>;
};

export const RemarkTh: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <th className="border border-gray-300 bg-gray-100 px-4 py-2 text-left">{children}</th>;
};

export const RemarkTd: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <td className="border border-gray-300 px-4 py-2">{children}</td>;
};

export const RemarkTr: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <tr>{children}</tr>;
};
