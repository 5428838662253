import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouterLink } from "../RouterLink/RouterLink";

interface IProps {
    readonly id: string;
    readonly excerpt: string;
    readonly timeToRead: number;
    readonly frontmatter: {
        readonly date: string;
        readonly slug: string;
        readonly title: string;
        readonly description: string;
        readonly author: string;
        readonly tag: string;
    };
}

export const BlogPostPreview: React.FC<React.PropsWithChildren<IProps>> = ({ frontmatter, children, timeToRead }) => {
    const { title, slug, date, tag, description } = frontmatter;
    const [t] = useTranslation();

    return (
        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
            <RouterLink href={slug}>{children}</RouterLink>
            <div>
                <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={date} className="text-gray-500">
                        {date} • {t("blogIndexPage.readingTime", { minutes: timeToRead })}
                    </time>

                    <p className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{tag}</p>
                </div>
                <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        <RouterLink href={slug}>
                            <span className="absolute inset-0" />
                            {title}
                        </RouterLink>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">{description}</p>
                    <div className="relative mt-8 flex items-center gap-x-4">
                        <div className="text-sm leading-6">
                            <RouterLink href={slug}>{t("blogIndexPage.readMore")} →</RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};
