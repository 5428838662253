import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { QuestionsList } from "./QuestionsList";

export const DS160QuestionsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("aboutDS160Page.title");
    const description = t("aboutDS160Page.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.AboutDS160}>
            <QuestionsList />
        </ScrollablePageTemplate>
    );
};
