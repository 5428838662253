import { XCircleIcon } from "@heroicons/react/20/solid";
import * as React from "react";
export const ErroredBanner: React.FC = () => {
    return (
        <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
                <div className="shrink-0">
                    <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">There was an error submitting your form. Please resubmit it.</h3>
                </div>
            </div>
        </div>
    );
};
