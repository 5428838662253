import { Link } from "gatsby";
import * as React from "react";

export interface IRemarkInternalLinkProps {
    readonly to: string;
    readonly alt: string;
}

export const RemarkInternalLink: React.FC<React.PropsWithChildren<IRemarkInternalLinkProps>> = ({ to, alt, children }) => {
    return (
        <Link to={to} className="font-semibold text-indigo-600 mt-2 mb-2 block" aria-label={alt}>
            {children}
        </Link>
    );
};
