import { Link } from "gatsby";
import * as React from "react";
import { useScaledImages } from "../../../hooks/useScaledImage";
import { AppRoute } from "../../../services/Constants/AppRoute";

interface IResource {
    readonly id: number;
    readonly title: string;
    readonly href: string;
    readonly imageUrl: string;
    readonly alt: string;
}

export const ResourcesList: React.FC = () => {
    const scaledImages = useScaledImages();
    const resources: Array<IResource> = [
        {
            id: 1,
            title: "Basics and Common Questions",
            href: AppRoute.AboutDS160,
            imageUrl: scaledImages.get("common-questions-featured") ?? "",
            alt: "Question mark",
        },
        {
            id: 2,
            title: "Document Checklist",
            href: AppRoute.DS160Checklist,
            imageUrl: scaledImages.get("checklist-feature-image") ?? "",
            alt: "Photo of a notepad with a checklist",
        },
        {
            id: 3,
            title: "Sample Completed Forms",
            href: AppRoute.SampleForms,
            imageUrl: scaledImages.get("sample-questions-featured") ?? "",
            alt: "Photo of sample petri dishes",
        },
    ];
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">DS-160 Resources</h2>
                    <p className="mt-2 text-lg/8 text-gray-600">
                        Here a compilation of free useful resources to help you with your next US DS-160 visa application
                    </p>
                </div>
                <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {resources.map((post) => (
                        <article
                            key={post.id}
                            className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                        >
                            <img alt={post.alt} src={post.imageUrl} className="absolute inset-0 -z-10 size-full object-cover" />
                            <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                            <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                            <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
                                <div className="-ml-4 flex items-center gap-x-4">
                                    <svg viewBox="0 0 2 2" className="-ml-0.5 size-0.5 flex-none fill-white/50">
                                        <circle r={1} cx={1} cy={1} />
                                    </svg>
                                </div>
                            </div>
                            <h3 className="mt-3 text-lg/6 font-semibold text-white">
                                <Link to={post.href}>
                                    <span className="absolute inset-0" />
                                    {post.title}
                                </Link>
                            </h3>
                        </article>
                    ))}
                </div>
            </div>
        </div>
    );
};
