import { SampleFormInputKey } from "@ds160/library";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { FormNavProvider } from "../../molecules/FormNavContext/FormNavContext";
import { SampleForm } from "../../organisms/SampleForm/SampleForm";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";

interface IProps {
    readonly formKey: SampleFormInputKey;
}

export const ViewSampleFormPage: React.FC<React.PropsWithChildren<IProps>> = ({ formKey }) => {
    const [t] = useTranslation();
    const title = t("sampleDS160FormsPage.title");
    const description = t("sampleDS160FormsPage.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.ViewSampleForm(formKey)}>
            <FormNavProvider formId={formKey}>
                <SampleForm />
            </FormNavProvider>
        </ScrollablePageTemplate>
    );
};
