import * as React from "react";
import { useFormsList } from "../../../../hooks/api/form/useFormsList";
import { BreadCrumb, IPage } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../../atoms/Loading/Loading";
import { FormsList } from "./FormsList";
import { NewForm } from "./NewForms";

export const MyForms: React.FC = () => {
    const applications = useFormsList();
    const pages: Array<IPage> = [{ name: "Forms" }];

    if (applications.isLoading) {
        return <Loading />;
    }

    const hasForms = (applications.data?.length ?? 0) > 0;
    const newFormTitle = hasForms ? "New Form" : "No Forms";
    const newFormDescription = hasForms ? "Create a new DS160 form" : "Get started by creating a new DS160 form.";

    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>
            <FormsList />
            <NewForm title={newFormTitle} description={newFormDescription} />
        </>
    );
};
