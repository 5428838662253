import { Link } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { Constants } from "../../../services/Constants/Constants";
import { NavLogo } from "../LogoSymbol/NavLogo";
import { RouterLink } from "../RouterLink/RouterLink";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";
import { LogoWrapper, MenuLinkStyledOverride } from "./Footer.styles";

interface INavigationLink {
    readonly name: string;
    readonly href: string;
    readonly icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const navigation: Array<INavigationLink> = [];

export const Footer: React.FC<React.PropsWithChildren<unknown>> = ({}) => {
    const [t] = useTranslation();
    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex justify-center gap-x-6 md:order-2">
                    <LogoWrapper>
                        <RouterLink href={AppRoute.Home} aria-label={t("footer.home")}>
                            <NavLogo />
                        </RouterLink>
                    </LogoWrapper>
                    {navigation.map((item) => (
                        <Link key={item.name} to={item.href} className="text-gray-600 hover:text-gray-800" target="_blank">
                            <span className="sr-only">{item.name}</span>
                            <item.icon aria-hidden="true" className="h-6 w-6" />
                        </Link>
                    ))}
                </div>
                <p className="mt-8 text-center text-sm/6 text-gray-600 md:order-1 md:mt-0">
                    {`${t("footer.copyright")} | `}
                    <TelemetryLink href={Constants.MailToLink} styledOverride={MenuLinkStyledOverride}>
                        {t("footer.contact")}
                    </TelemetryLink>
                    {` | `}
                    <TelemetryLink href={Constants.PrivacyLink} target="_blank">
                        {t("footer.privacy")}
                    </TelemetryLink>
                </p>
            </div>
        </footer>
    );
};
