import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { ResourcesList } from "./ResourcesList";

export const ResourcesPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("resourcesPage.title");
    const description = t("resourcesPage.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.Pricing}>
            <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
            <ResourcesList />
        </ScrollablePageTemplate>
    );
};
