import { IPaypalOrderDetails, TelemetryEvent } from "@ds160/library";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertType, AlertsContext, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { useAuth } from "../../useAuth";

const getMutationQuery = (token: string | null) => {
    const postRequest = async (order: IPaypalOrderDetails): Promise<unknown> => {
        const config = getAxiosRequestConfig(token);
        const { data } = await axios.post(`${Constants.ApiRoot}/order/link`, { order }, config);
        return data;
    };
    return postRequest;
};

export const usePostLinkOrder = (onSuccess?: (response: unknown) => Promise<void>) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);

    const mutation = useMutation(getMutationQuery(authToken), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.LinkOrderStart, null);
        },
        onSuccess: async (data) => {
            await telemetry.Log(TelemetryEvent.LinkOrderSuccess, null);
            await onSuccess?.(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.LinkOrderError, submitError);
        },
    });

    return { mutation };
};
