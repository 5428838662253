import { VisaType } from "@ds160/library";
import { Link } from "@reach/router";
import * as React from "react";
import { useMemo } from "react";
import { useFieldsList } from "../../../hooks/api/form/field/useFieldsList";
import { useFormNav } from "../../molecules/FormNavContext/FormNavContext";
import { getFormSections } from "../FormOverviewPage/getFormSections";
import { hasVisaType } from "../FormOverviewPage/hasVisaType";

export const FormNavigation: React.FC = () => {
    const { formId } = useFormNav();
    const fields = useFieldsList(formId);
    const isFVisa = hasVisaType(fields.data?.raw ?? [], VisaType.ACADEMIC_OR_LANGUAGE_STUDENT_F);
    const sections = useMemo(() => getFormSections(formId, isFVisa), [formId, isFVisa]);
    const currentSectionIndex = sections.findIndex((section) => section.href === window.location.pathname);
    const sectionPosition = currentSectionIndex + 1;

    const isNextDisabled = currentSectionIndex === sections.length - 1;
    const isPrevDisabled = currentSectionIndex <= 0;

    const prevLink = sections[currentSectionIndex - 1]?.href ?? "";
    const nextLink = sections[currentSectionIndex + 1]?.href ?? "";

    return (
        <nav aria-label="Pagination" className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Showing Section <span className="font-medium">{sectionPosition}</span> of{" "}
                    <span className="font-medium">{sections.length}</span>
                </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
                <Link
                    to={prevLink}
                    onClick={(e) => isPrevDisabled && e.preventDefault()}
                    className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
                        isPrevDisabled
                            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                            : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    } focus-visible:outline-offset-0`}
                    aria-disabled={isPrevDisabled}
                >
                    Previous
                </Link>
                <Link
                    to={nextLink}
                    onClick={(e) => isNextDisabled && e.preventDefault()}
                    className={`relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
                        isNextDisabled
                            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                            : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    } focus-visible:outline-offset-0`}
                    aria-disabled={isNextDisabled}
                >
                    Next
                </Link>
            </div>
        </nav>
    );
};
