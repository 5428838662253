import { assertValidPassword, IPaypalOrderDetails, ValidationError } from "@ds160/library";
import { navigate } from "gatsby";
import { t } from "i18next";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { IPostSessionRequestResponseBody, usePostSession } from "../../../../hooks/api/session/usePostSession";
import { useAuth } from "../../../../hooks/useAuth";
import { useInputState } from "../../../../hooks/useInputState";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { AppRoute } from "../../../../services/Constants/AppRoute";
import { SessionStorageKey } from "../../../../services/Constants/SessionStorageKey";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Form } from "../../../atoms/Form/Form";
import { P } from "../../../atoms/P/P";
import { TextInput } from "../../../atoms/TextInput/TextInput";
import { WithoutAuth } from "../../../molecules/Auth/WithoutAuth";
import { RouterLink } from "../../../molecules/RouterLink/RouterLink";
import { Heading, LoginFormContainer } from "./LoginForm.styles";

const getValidationErrorMessage = (password: string) => {
    try {
        assertValidPassword(password);
    } catch (error) {
        if (error instanceof ValidationError) {
            return error.message;
        }
        throw error;
    }
    return null;
};

export const LoginForm: React.FC = () => {
    const { setAuthToken } = useAuth();
    const [redirectUrl, setRedirectUrl] = useSessionStorage(SessionStorageKey.LoginRedirectUrl, null);
    const [email, , onEmailChanged] = useInputState("");
    const [password, , onPasswordChanged] = useInputState("");
    const [_, setGuestJobId] = useSessionStorage(SessionStorageKey.GuestJobId, null);

    const [rawOrderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    const orderDetails = useMemo(() => {
        const order = typeof rawOrderDetails === "string" ? (JSON.parse(rawOrderDetails) as IPaypalOrderDetails) : null;
        return order ?? undefined;
    }, [rawOrderDetails]);

    const onSubmitSuccess = useCallback(
        async (response: IPostSessionRequestResponseBody) => {
            setGuestJobId(null);
            setOrderDetails(null);
            setAuthToken?.(response.token);
            setRedirectUrl(null);
            if (redirectUrl !== null) {
                navigate(redirectUrl);
            }
        },
        [redirectUrl, setAuthToken, setGuestJobId, setOrderDetails, setRedirectUrl]
    );

    const { mutation } = usePostSession({ email, password, order: orderDetails }, onSubmitSuccess);

    const onSubmit = useCallback(() => {
        mutation.mutate();
    }, [mutation]);

    const emailPlaceholder = t("loginPage.email");
    const passwordPlaceholder = t("loginPage.password");

    const isLoading = mutation.isLoading === true;
    const validationErrorMessage = useMemo(() => getValidationErrorMessage(password), [password]);

    const isFormIncomplete = password.length <= 0 || password.length <= 0;

    const isSubmitDisabled = (validationErrorMessage !== null && isFormIncomplete === false) || isLoading;

    const shouldShowErrorMessage = isFormIncomplete === false && validationErrorMessage !== null;
    return (
        <WithoutAuth>
            <LoginFormContainer>
                <Form onSubmit={onSubmit}>
                    <Heading>{t("loginPage.login")}</Heading>
                    <TextInput type="text" value={email} onChange={onEmailChanged} placeholder={emailPlaceholder} disabled={isLoading} />
                    <TextInput
                        type="password"
                        value={password}
                        onChange={onPasswordChanged}
                        placeholder={passwordPlaceholder}
                        disabled={isLoading}
                    />
                    <PrimaryButton disabled={isSubmitDisabled} type="submit">
                        {t("loginPage.login")}
                    </PrimaryButton>
                    {shouldShowErrorMessage && <span>{validationErrorMessage}</span>}
                </Form>

                <P>
                    <Trans key="noAccountSignup">
                        No account? <RouterLink href={AppRoute.Signup}>Sign up</RouterLink>
                    </Trans>
                </P>
            </LoginFormContainer>
        </WithoutAuth>
    );
};
