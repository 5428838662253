import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { ApplicationShell } from "../../molecules/ApplicationShell/ApplicationShell";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { useFormNav } from "../../molecules/FormNavContext/FormNavContext";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { NewSubmission } from "../SubmissionsPage/MySubmissions/NewSubmission";
import { FormSectionList } from "./FormSectionList";

interface IProps {}

export const FormOverviewPage: React.FC<React.PropsWithChildren<IProps>> = ({}) => {
    const [t] = useTranslation();
    const description = t("formOverviewPage.description");
    const title = t("formOverviewPage.title");
    const { formId } = useFormNav();
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.FormOverview(formId)} showNavigation={false}>
            <WithAuth>
                <ApplicationShell>
                    <FormSectionList />
                    <NewSubmission />
                </ApplicationShell>
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
