import * as React from "react";
import { useForm } from "../../../hooks/api/form/useForm";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { RedirectTo } from "../../molecules/RedirectTo/RedirectTo";
import { formManifest, FormPage, formPageJsonPaths, FormQuestion } from "../../pages/FormsPage/Form/manifest/formManifest";
import { Question } from "../../pages/FormsPage/Form/Question";

interface IProps {
    readonly formId: string;
    readonly formPage: FormPage;
}

export const SectionViewer: React.FC<React.PropsWithChildren<IProps>> = ({ formId, formPage }) => {
    const form = useForm(formId);

    const jsonPath = `$.${formPageJsonPaths[formPage ?? ""]}`;
    const questionList = formManifest[formPage ?? ""].map((question: FormQuestion) => (
        <Question currentJsonPath={jsonPath} key={question.jsonPath} question={question} isReadonly={true} />
    ));
    const isValidFormPage = form !== undefined;
    if (isValidFormPage === false) {
        return <RedirectTo to={AppRoute.FormOverview(formId)} />;
    }

    return <>{questionList}</>;
};
