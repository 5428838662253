import { PlusIcon } from "@heroicons/react/20/solid";
import { navigate } from "gatsby";
import * as React from "react";
import { useCallback } from "react";
import { useCreateForm } from "../../../../hooks/api/form/useCreateForm";
import { AppRoute } from "../../../../services/Constants/AppRoute";

interface IProps {
    readonly title: string;
    readonly description: string;
}

export const NewForm: React.FC<IProps> = ({ title, description }) => {
    const onCreateFormSuccess = useCallback((response: { formId: string }) => {
        const formId = response.formId;
        navigate(AppRoute.FormOverview(formId));
    }, []);

    const createForm = useCreateForm(onCreateFormSuccess);

    const onNewFormClicked = useCallback(() => {
        createForm.mutation.mutate();
    }, [createForm.mutation]);
    return (
        <div className="text-center border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-auto size-12 text-gray-400"
            >
                <path
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
            </svg>

            <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
            <div className="mt-6">
                <button
                    type="button"
                    onClick={onNewFormClicked}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                    New Form
                </button>
            </div>
        </div>
    );
};
