import { PageProps } from "gatsby";
import * as React from "react";
import { CheckoutProvider } from "../../components/molecules/CheckoutContext/CheckoutContext";
import { CheckoutPage } from "../../components/pages/CheckoutPage/CheckoutPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const productCode = params?.productCode;
    return (
        <CheckoutProvider productCode={productCode}>
            <CheckoutPage />;
        </CheckoutProvider>
    );
};
export default Page;
