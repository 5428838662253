import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { AuthorizedPaymentBanner } from "../../molecules/AuthorizedPaymentBanner/AuthorizedPaymentBanner";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { GoogleLoginButton } from "./GoogleLoginButton/GoogleLoginButton";
import { LoginForm } from "./LoginForm/LoginForm";
export const LoginPage: React.FC = () => {
    const [t] = useTranslation();
    const title = t("loginPage.title");
    const description = t("loginPage.description");
    const [orderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);
    return (
        <StandardPageTemplate title={title} description={description} canonical={AppRoute.Login}>
            {orderDetails !== null && <AuthorizedPaymentBanner />}
            <LoginForm />
            <GoogleLoginButton />
        </StandardPageTemplate>
    );
};
