import { SubmissionStatus } from "@ds160/library";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";
import * as React from "react";
import { classNames } from "../../../../functions/classNames";
import { useSubmissionList } from "../../../../hooks/api/submission/useSubmissionList";
import { AppRoute } from "../../../../services/Constants/AppRoute";
import { Loading } from "../../../atoms/Loading/Loading";
import { timeFromNow } from "../../../molecules/App/locales/timeFromNow";

const statusBadgeClassNames: Record<SubmissionStatus, string> = {
    [SubmissionStatus.AwaitingCaptcha]: "text-blue-700 bg-blue-50 ring-blue-600/20",
    [SubmissionStatus.Completed]: "text-green-700 bg-green-50 ring-green-600/20",
    [SubmissionStatus.Errored]: "text-red-700 bg-red-50 ring-red-600/20",
    [SubmissionStatus.FillingForm]: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
    [SubmissionStatus.InProgress]: "text-gray-600 bg-gray-50 ring-gray-500/10",
    [SubmissionStatus.PendingStart]: "text-purple-700 bg-purple-50 ring-purple-600/20",
};

const friendlyStatuses: Record<SubmissionStatus, string> = {
    [SubmissionStatus.AwaitingCaptcha]: "Awaiting captcha",
    [SubmissionStatus.Completed]: "Completed",
    [SubmissionStatus.Errored]: "Errored",
    [SubmissionStatus.FillingForm]: "Filling form",
    [SubmissionStatus.InProgress]: "In progress",
    [SubmissionStatus.PendingStart]: "Pending start",
};

export const SubmissionsList: React.FC = () => {
    const submissions = useSubmissionList();

    if (submissions.isLoading) {
        return <Loading />;
    }

    const submissionList = submissions.data?.map((project) => {
        const createdDate = new Date(project.created);
        const relativeTime = timeFromNow(createdDate);
        const readableStatus = friendlyStatuses[project.status];
        return (
            <li key={project._id} className="flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                        <p className="text-sm/6 font-semibold text-gray-900">{project.projectName}</p>
                        <p
                            className={classNames(
                                statusBadgeClassNames[project.status],
                                "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                            )}
                        >
                            {readableStatus}
                        </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs/5 text-gray-500">
                        <p className="truncate">Created {relativeTime}</p>
                    </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                    <Link
                        to={AppRoute.EditSubmission(project._id)}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                        View Application<span className="sr-only">, {project.projectName}</span>
                    </Link>
                    <Menu as="div" className="relative flex-none">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                        </MenuButton>
                        <MenuItems
                            transition
                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <MenuItem>
                                <a
                                    href="#"
                                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                >
                                    Edit<span className="sr-only">, {project.projectName}</span>
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a
                                    href="#"
                                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                >
                                    Move<span className="sr-only">, {project.projectName}</span>
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a
                                    href="#"
                                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                >
                                    Delete<span className="sr-only">, {project.projectName}</span>
                                </a>
                            </MenuItem>
                        </MenuItems>
                    </Menu>
                </div>
            </li>
        );
    });

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {submissionList}
        </ul>
    );
};
