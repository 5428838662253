import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {
    readonly captcha: string;
}

const getCaptcha = async (config: AxiosRequestConfig, submissionId: string) => {
    const uri = `${Constants.ApiRoot}/submission/${submissionId}/captcha`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useSubmissionCaptcha = (submissionId: string) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Submissions, QueryCacheKey.Captcha, submissionId], () => getCaptcha(config, submissionId), {
        cacheTime: 60000,
    });
};
