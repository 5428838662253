import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { SessionStorageKey } from "../../../services/Constants/SessionStorageKey";
import { AuthorizedPaymentBanner } from "../../molecules/AuthorizedPaymentBanner/AuthorizedPaymentBanner";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { GoogleLoginButton } from "../LoginPage/GoogleLoginButton/GoogleLoginButton";
import { SignupForm } from "./SignupForm/SignupForm";

export const SignupPage: React.FC = () => {
    const [t] = useTranslation();
    const title = t("signupPage.title");
    const description = t("signupPage.description");
    const [orderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    return (
        <StandardPageTemplate title={title} description={description} canonical={AppRoute.Signup}>
            {orderDetails !== null && <AuthorizedPaymentBanner />}
            <SignupForm />
            <GoogleLoginButton />
        </StandardPageTemplate>
    );
};
