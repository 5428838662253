import { CouponType, getPrice, ProductCode } from "@ds160/library";
import * as React from "react";
import { useCallback, useState } from "react";
import { useProductCoupon } from "../../../hooks/api/order/useProductCoupon";
import { useDebounce } from "../../../hooks/useDebounce";
import { useCheckout } from "../../molecules/CheckoutContext/CheckoutContext";
import { DiscountBanner } from "./DiscountBanner";
import logo from "./logo-icon.svg";
import { PaypalPurchase } from "./PaypalPurchase/PaypalPurchase";

interface IProduct {
    readonly id: number;
    readonly name: string;
    readonly priceCents: number;
    readonly imageAlt: string;
}

const products: Array<IProduct> = [
    {
        id: 1,
        name: "DS160 Form Pilot Submission",
        priceCents: 1000,
        imageAlt: "DS160 Form Pilot Submission Icon",
    },
];

const initialQuantity: Record<ProductCode, number> = {
    [ProductCode.Single]: 1,
    [ProductCode.ThreePack]: 3,
};

const getSubtotalCentsWithDiscount = (type: CouponType | null, amount: number | null, subtotalCents: number) => {
    switch (type) {
        case CouponType.Discount:
            return Math.round(subtotalCents * (1 - (amount ?? 0)));
        case CouponType.Free:
            return 0;
        default:
            return subtotalCents;
    }
};

export const CheckoutHero: React.FC = () => {
    const checkout = useCheckout();
    const initialQty = initialQuantity[checkout.productCode];
    const [quantity, setQuantity] = useState(initialQty);
    const [couponCode, setCouponCode] = useState("");
    const [couponCodeFieldValue, setCouponCodeFieldValue] = useState("");
    const coupon = useProductCoupon(couponCode, (r) => {
        console.log(r);
    });

    const onQuantityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseInt(event.target.value, 10);
        if (parsedValue < 1) {
            setQuantity(1);
            return;
        }
        setQuantity(parsedValue);
    }, []);
    const fetchCoupon = useDebounce((upperCaseCode: string) => {
        setCouponCode(upperCaseCode);
    }, 500);

    const onCouponCodeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const upperCaseCode = event.target.value.trim().toUpperCase();
            setCouponCodeFieldValue(upperCaseCode);
            fetchCoupon(upperCaseCode);
        },
        [fetchCoupon]
    );

    const pricePerUnitCents = getPrice(quantity);
    const subtotalCents = quantity * pricePerUnitCents;
    const discountAmount = coupon.data?.amount ?? null;
    const subtotalCentsWithDiscount = getSubtotalCentsWithDiscount(coupon.data?.type ?? null, discountAmount, subtotalCents);

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:px-0">
                <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Checkout</h1>

                <form className="mt-12">
                    <section aria-labelledby="cart-heading">
                        <h2 id="cart-heading" className="sr-only">
                            Items in your shopping cart
                        </h2>

                        <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                            {products.map((product) => (
                                <li key={product.id} className="flex py-6">
                                    <div className="shrink-0">
                                        <img alt={product.imageAlt} src={logo} className="size-24 rounded-md object-contain sm:size-32" />
                                    </div>

                                    <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                                        <div>
                                            <div className="flex justify-between">
                                                <h4 className="text-sm">
                                                    <span className="font-medium text-gray-700 hover:text-gray-800">{product.name}</span>
                                                </h4>
                                                <div className="ml-4 text-right">
                                                    <p className="text-xs text-gray-500">Unit Price</p>
                                                    <p className="text-sm font-medium text-gray-900">${pricePerUnitCents / 100}</p>
                                                </div>
                                            </div>
                                            <label htmlFor="quantity-input" className="mt-1 text-sm text-gray-500">
                                                Quantity
                                            </label>
                                            <input
                                                onChange={onQuantityChange}
                                                type="number"
                                                id="quantity-input"
                                                aria-describedby="helper-text-explanation"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={quantity}
                                                required
                                            />

                                            <label htmlFor="coupon-input" className="mt-1 text-sm text-gray-500">
                                                Coupon code
                                            </label>
                                            <input
                                                onChange={onCouponCodeChange}
                                                type="text"
                                                id="coupon-input"
                                                aria-describedby="helper-text-explanation"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={couponCodeFieldValue}
                                                required
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Order summary */}
                    <section aria-labelledby="summary-heading" className="mt-10">
                        <h2 id="summary-heading" className="sr-only">
                            Order summary
                        </h2>
                        <DiscountBanner amount={discountAmount} />
                        <div>
                            <dl className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <dt className="text-2xl font-medium text-gray-900">Subtotal</dt>
                                    {<dd className="ml-4 text-2xl font-medium text-gray-900">${subtotalCentsWithDiscount / 100}</dd>}
                                </div>
                            </dl>
                            <p className="mt-1 text-sm text-gray-500">Taxes will be calculated at checkout.</p>
                        </div>
                    </section>
                </form>
                <div className="mt-10">
                    <PaypalPurchase
                        quantity={quantity}
                        pricePerUnit={pricePerUnitCents}
                        couponCode={couponCodeFieldValue}
                        subtotalCentsWithDiscount={subtotalCentsWithDiscount}
                    />
                </div>
            </div>
        </div>
    );
};
