import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import * as React from "react";

interface IQuestion {
    readonly question: string;
    readonly answer: string;
}

const faqs: Array<IQuestion> = [
    {
        question: "What is a DS-160 Form?",
        answer: "The DS-160 is an online nonimmigrant visa application form used to collect information from individuals applying for a U.S. visa. It is a mandatory form for most visa categories, including tourists, students, and business travelers. The form is submitted electronically through the Consular Electronic Application Center (CEAC).",
    },
    {
        question: "What is the DS-160 number?",
        answer: "The DS-160 number, also known as the application ID, is a unique identifier assigned to your application. It is located at the top-right corner of your DS-160 confirmation page. You’ll need this number to schedule a visa appointment and track your application status.",
    },
    {
        question: "How long does the DS-160 last?",
        answer: "The DS-160 form does not have a fixed expiration date, but it is tied to the visa application process. You must schedule a visa appointment within one year of submitting the form for it to remain valid. After that, the application may need to be resubmitted.",
    },
    {
        question: "When does the DS-160 expire?",
        answer: "The DS-160 form expires if you do not schedule a visa interview within one year of submission. This ensures that the information provided in the application remains up to date. If expired, you will need to complete and submit a new DS-160 form.",
    },
    {
        question: "How long is the DS-160 valid?",
        answer: "The DS-160 is valid for one year after submission, but only if it is used to schedule a visa appointment during that time. Once tied to a visa interview, it remains valid for that specific process. Be sure to submit a new form if there are delays or changes in your plans.",
    },
    {
        question: "Can the DS-160 be edited?",
        answer: "Yes, the DS-160 can be edited as long as it has not been submitted. After submission, changes cannot be made directly to the form. However, you can start a new application if significant updates are required and use the updated confirmation page.",
    },
    {
        question: "Can the DS-160 be updated after submission?",
        answer: "No, the submitted DS-160 form cannot be updated directly. If updates are necessary, you must complete and submit a new form. You will need to notify the embassy or consulate and provide the new DS-160 confirmation number for your visa interview.",
    },
    {
        question: "Can the DS-160 be updated after scheduling an appointment?",
        answer: "Yes, you can submit a new DS-160 form after scheduling your appointment if necessary. Ensure you inform the embassy or consulate of the updated DS-160 confirmation number. This is important to avoid discrepancies during your visa interview.",
    },
    {
        question: "What does the DS-160 look like?",
        answer: "The DS-160 is an online form accessed through the CEAC website, divided into multiple sections. It includes questions about your personal details, travel plans, work history, and security-related information. Upon submission, you receive a confirmation page with a barcode and application ID.",
    },
    {
        question: "Will the DS-160 expire?",
        answer: "Yes, the DS-160 will expire if you do not use it to schedule a visa appointment within one year of submission. This ensures the information in the application remains current for your visa process. After expiration, you will need to submit a new DS-160 form.",
    },
    {
        question: "Can the DS-160 and appointment location be different?",
        answer: "Yes, you can choose a different appointment location from the one specified in your DS-160 form. However, it’s important to ensure the embassy or consulate you select is aware of this change. Always verify that the selected location can process your visa type.",
    },
    {
        question: "Can I cancel the DS-160?",
        answer: "You cannot cancel a submitted DS-160 form. If you no longer wish to use it, you can simply disregard it and submit a new application if needed. The original form will not affect your visa process as long as you proceed with the new one.",
    },
    {
        question: "How do I pay for the DS-160?",
        answer: "There is no fee for the DS-160 itself, but visa application fees must be paid separately through the embassy or consulate’s payment portal. Payment methods vary by country and may include bank deposits, online payments, or in-person transactions. Keep your receipt as proof of payment.",
    },
    {
        question: "What do I need for the DS-160?",
        answer: "You will need a valid passport, your travel itinerary, details of previous U.S. visits or visas (if applicable), and information about your current employment or education. Additional details, such as family information or your intended U.S. contact, may also be required.",
    },
    {
        question: "What does the DS-160 mean?",
        answer: "The DS-160 is short for 'Nonimmigrant Visa Application.' It is a required step in applying for a U.S. visa and collects important information about your eligibility. Completing the DS-160 is the first step in the visa application process.",
    },
    {
        question: "When does the DS-160 expire?",
        answer: "The DS-160 expires if not used to schedule a visa appointment within one year of submission. To avoid delays, ensure you schedule your interview promptly. After expiration, you will need to fill out and submit a new form.",
    },
    {
        question: "When should the DS-160 be submitted?",
        answer: "You should submit the DS-160 before scheduling your visa appointment. The confirmation number from the form is required to book an interview. Ensure all information is accurate and up-to-date before submission to avoid complications later.",
    },
    {
        question: "When will the DS-160 expire?",
        answer: "The DS-160 will expire one year after submission if you do not schedule a visa interview during that time. This timeline ensures the information remains current for processing. Submitting a new DS-160 is required if the original expires.",
    },
    {
        question: "Where do I pay for the DS-160?",
        answer: "You pay the visa application fee, not the DS-160 itself, through the payment system provided by the U.S. embassy or consulate. This may involve online payments, bank transfers, or in-person payments. Check the specific instructions for your country of application.",
    },
    {
        question: "Where can I find the DS-160 number?",
        answer: "The DS-160 number is printed on the confirmation page of your application. It is located in the top-right corner and is also referred to as the application ID. Keep this number safe, as it is essential for scheduling and tracking your visa appointment.",
    },
    {
        question: "Where is the DS-160 number located?",
        answer: "The DS-160 number is found on the confirmation page at the top-right corner. This number is also called the application ID and is required for your visa appointment scheduling. Ensure you keep a copy of the confirmation page for your records.",
    },
    {
        question: "Which number is the DS-160 number?",
        answer: "The DS-160 number is the unique application ID assigned to your form. It appears on the confirmation page at the top-right corner. This number is essential for tracking your application and scheduling your visa interview.",
    },
    {
        question: "Who needs to complete the DS-160?",
        answer: "Anyone applying for a U.S. nonimmigrant visa, such as tourists, students, or business travelers, needs to complete the DS-160 form. Each applicant, including children, must have their own DS-160 submitted. It is a critical step in the visa application process.",
    },
];

export const QuestionsList: React.FC = () => {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">DS-160 Basics</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure key={faq.question} as="div" className="pt-6">
                                <dt>
                                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base/7 font-semibold">{faq.question}</span>
                                        <span className="ml-6 flex h-7 items-center">
                                            <PlusIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                            <MinusIcon aria-hidden="true" className="size-6 group-[&:not([data-open])]:hidden" />
                                        </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel as="dd" className="mt-2 pr-12">
                                    <p className="text-base/7 text-gray-600">{faq.answer}</p>
                                </DisclosurePanel>
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};
