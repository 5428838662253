import { getPrice, ProductCode } from "@ds160/library";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";
import * as React from "react";
import { classNames } from "../../../functions/classNames";
import { AppRoute } from "../../../services/Constants/AppRoute";
interface IPricingTier {
    readonly name: string;
    readonly productCode: ProductCode;
    readonly href: string;
    readonly priceCentsPerUnit: number;
    readonly bundleDescription: string;
    readonly description: string;
    readonly features: string[];
    readonly featured: boolean;
}

const tiers: Array<IPricingTier> = [
    {
        name: "Single Form",
        productCode: ProductCode.Single,
        href: AppRoute.Checkout(ProductCode.Single),
        priceCentsPerUnit: getPrice(1),
        bundleDescription: "Single unit pricing",
        description: "Perfect for individuals.",
        features: [
            "No more time wasted on DS150 timeouts.",
            "Works for all visa types F-1, H-1B, E-3 and many more.",
            "Your DS160 form filled out in seconds.",
            "The last DS160 you will ever complete.",
        ],
        featured: true,
    },
    {
        name: "Three or More Forms",
        productCode: ProductCode.ThreePack,
        href: AppRoute.Checkout(ProductCode.ThreePack),
        priceCentsPerUnit: getPrice(3),
        bundleDescription: "Price per unit for 3+ units",
        description: "Great for families.",
        features: ["Everything in Single form.", "Generous discount."],
        featured: false,
    },
];

export const PricingHero: React.FC = () => {
    return (
        <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                />
            </div>
            <div className="mx-auto max-w-4xl text-center">
                <h2 className="text-base/7 font-semibold text-indigo-600">Pricing</h2>
                <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">${getPrice(1) / 100}</p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
                Saves you 120 minutes on each DS-160 form.
            </p>
            <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
                {tiers.map((tier, tierIdx) => (
                    <div
                        key={tier.productCode}
                        className={classNames(
                            tier.featured ? "relative bg-white shadow-2xl" : "bg-white/60 sm:mx-8 lg:mx-0",
                            tier.featured
                                ? ""
                                : tierIdx === 0
                                  ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
                                  : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl",
                            "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
                        )}
                    >
                        <h3 id={tier.productCode} className="text-base/7 font-semibold text-indigo-600">
                            {tier.name}
                        </h3>
                        <p className="mt-4 flex items-baseline gap-x-2">
                            <span className="text-5xl font-semibold tracking-tight text-gray-900">${tier.priceCentsPerUnit / 100}</span>
                            <span className="text-base text-gray-500">per form</span>
                        </p>
                        <p className="mt-6 text-base/7 text-gray-600">{tier.description}</p>
                        <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600 sm:mt-10">
                            {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                        <Link
                            to={tier.href}
                            aria-describedby={tier.productCode}
                            className={classNames(
                                tier.featured
                                    ? "bg-indigo-600 text-white shadow hover:bg-indigo-500"
                                    : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                                "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
                            )}
                        >
                            Buy {tier.name}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};
