import { SampleFormInputKey } from "@ds160/library";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { FormNavProvider } from "../../molecules/FormNavContext/FormNavContext";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { SampleSelection } from "./SampleSelection";

export const SampleDS160FormsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("sampleDS160FormsPage.title");
    const description = t("sampleDS160FormsPage.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.AboutDS160}>
            <FormNavProvider formId={SampleFormInputKey.Complex}>
                <SampleSelection />
            </FormNavProvider>
        </ScrollablePageTemplate>
    );
};
