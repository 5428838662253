import { VisaType } from "@ds160/library";
import { Link } from "gatsby";
import * as React from "react";
import { useMemo } from "react";
import { useFieldsList } from "../../../hooks/api/form/field/useFieldsList";
import { useForm } from "../../../hooks/api/form/useForm";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { BreadCrumb, IPage } from "../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../atoms/Loading/Loading";
import { useFormNav } from "../../molecules/FormNavContext/FormNavContext";
import { getFormSections } from "./getFormSections";
import { hasVisaType } from "./hasVisaType";

interface IProps {}

export interface ISection {
    readonly label: string;
    readonly href: string;
}

export const FormSectionList: React.FC<IProps> = () => {
    const { formId } = useFormNav();
    const form = useForm(formId);
    const fields = useFieldsList(formId);
    const isFVisa = hasVisaType(fields.data?.raw ?? [], VisaType.ACADEMIC_OR_LANGUAGE_STUDENT_F);
    const sections = useMemo(() => getFormSections(formId, isFVisa), [formId, isFVisa]);

    const pages: Array<IPage> = [{ name: "Forms", href: AppRoute.Forms }, { name: form.data?.name ?? "" }];

    if (form.isLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 mb-5">
                <BreadCrumb pages={pages} />
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold text-gray-900">Form Sections</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            This is a list of all the sections in the form, each with its name and a link to edit. After completing all
                            sections, you can generate a US DS-160 visa application, which our form filler will use to help you efficiently
                            complete your DS-160 form.
                        </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Section Name
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {sections.map((section) => (
                                        <tr key={section.href}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                {section.label}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <Link to={section.href} className="text-indigo-600 hover:text-indigo-900">
                                                    Edit<span className="sr-only">, {section.label}</span>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
