import { ShieldCheckIcon, KeyIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import * as React from "react";

const features = [
    {
        name: "Data Encryption",
        description:
            "Your data is encrypted using industry-standard AES-256 both in transit and at rest, ensuring it remains secure at all times.",
        href: "#",
        icon: ShieldCheckIcon,
    },
    {
        name: "Full Data Control",
        description:
            "You have complete control over your data. Delete your stored information anytime through your account settings with just a few clicks.",
        href: "#",
        icon: KeyIcon,
    },
    {
        name: "Compliance & Audits",
        description:
            "Our platform complies with global data protection standards, such as GDPR, and undergoes regular security audits to keep your information safe.",
        href: "#",
        icon: DocumentCheckIcon,
    },
];

export const SecurityFeature: React.FC = () => {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Secure and Private</h2>
                    <p className="mt-6 text-lg/8 text-gray-600">
                        At DS160.io, we prioritize the security and privacy of your personal information.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-base/7 font-semibold text-gray-900">
                                    <div className="mb-6 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon aria-hidden="true" className="size-6 text-white" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base/7 text-gray-600">
                                    <p className="flex-auto">{feature.description}</p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};
