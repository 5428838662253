import { useStaticQuery, graphql } from "gatsby";

export const useScaledImages = () => {
    const data = useStaticQuery(graphql`
        query ScaledImageMapQuery {
            allFile(filter: { sourceInstanceName: { eq: "scaled-images" }, extension: { regex: "/(jpg|jpeg|png|gif)/" } }) {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    `);
    console.log(data);

    const imageMap = new Map<string, string>();
    data.allFile.nodes.forEach((node: any) => {
        const imageSrc = node.childImageSharp?.gatsbyImageData.images.fallback?.src;
        if (imageSrc) {
            imageMap.set(node.name, imageSrc);
        }
    });

    return imageMap;
};
