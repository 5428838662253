import { SubmissionStatus, submissionStatusLabels } from "@ds160/library";
import * as React from "react";
import { useSubmission } from "../../../hooks/api/submission/useSubmission";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { BreadCrumb, IPage } from "../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../atoms/Loading/Loading";
import { ProgressBar } from "../../atoms/ProgressBar/ProgressBar";
import { Captcha } from "./Captcha";
import { CompletedBanner } from "./CompletedBanner";
import { ErroredBanner } from "./ErroredBanner";
import { Status } from "./Status";

interface IProps {
    readonly submissionId: string;
}

const steps: Array<SubmissionStatus> = [
    SubmissionStatus.PendingStart,
    SubmissionStatus.InProgress,
    SubmissionStatus.AwaitingCaptcha,
    SubmissionStatus.FillingForm,
    SubmissionStatus.Completed,
];

const labelledSteps = steps.map((step) => submissionStatusLabels[step]);

export const ViewSubmissionForm: React.FC<React.PropsWithChildren<IProps>> = ({ submissionId }) => {
    const submission = useSubmission(submissionId);
    if (submission.isLoading || submission.data === undefined) {
        return <Loading />;
    }
    const { projectName, status } = submission.data;
    const currentStep = steps.indexOf(status);
    const pages: Array<IPage> = [{ name: "Submissions", href: AppRoute.Submissions }, { name: projectName }];
    const formIsBeingFilled = status !== SubmissionStatus.Completed && status !== SubmissionStatus.Errored;
    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>

            <div className="px-4 sm:px-4 lg:px-6 pt-6">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold text-gray-900">DS160 Form Pilot: {projectName}</h1>
                    </div>
                </div>
                {status === SubmissionStatus.Errored && <ErroredBanner />}
                {status === SubmissionStatus.Completed && <CompletedBanner />}
                {formIsBeingFilled && <ProgressBar steps={labelledSteps} currentStep={currentStep} />}
                {formIsBeingFilled && <Loading />}
                {status === SubmissionStatus.AwaitingCaptcha && <Captcha submissionId={submissionId} />}
                <Status submissionId={submissionId} />
            </div>
        </>
    );
};
