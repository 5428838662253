import { SampleFormInputKey, VisaType } from "@ds160/library";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFieldsList } from "../../../hooks/api/form/field/useFieldsList";
import { useForm } from "../../../hooks/api/form/useForm";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { Constants } from "../../../services/Constants/Constants";
import { Loading } from "../../atoms/Loading/Loading";
import { useFormNav } from "../../molecules/FormNavContext/FormNavContext";
import { TelemetryLink } from "../../molecules/TelemetryLink/TelemetryLink";
import { hasVisaType } from "../../pages/FormOverviewPage/hasVisaType";
import { FormPage, formPageLabels } from "../../pages/FormsPage/Form/manifest/formManifest";
import { SectionViewer } from "./SectionViewer";

interface IProps {}

interface ISection {
    readonly label: string;
    readonly formPage: FormPage;
}

const labels: Record<SampleFormInputKey, string> = {
    [SampleFormInputKey.Simple]: "Simple",
    [SampleFormInputKey.Complex]: "Complex",
};
const samplePDFs: Record<SampleFormInputKey, string> = {
    [SampleFormInputKey.Simple]: Constants.ComplexDS160SampleLink,
    [SampleFormInputKey.Complex]: Constants.ComplexDS160SampleLink,
};

const descriptions: Record<SampleFormInputKey, string> = {
    [SampleFormInputKey.Simple]: "This is an example of the simplest DS-160 US visa form which contains ~230 responses.",
    [SampleFormInputKey.Complex]: "This is an example of the most complex DS-160 US visa form which contains ~550 responses.",
};

export const SampleForm: React.FC<IProps> = () => {
    const { formId } = useFormNav();
    const label = labels[formId];
    const description = descriptions[formId];
    const [t] = useTranslation();
    const url = samplePDFs[formId];
    const form = useForm(formId);
    const fields = useFieldsList(formId);
    const isFVisa = hasVisaType(fields.data?.raw ?? [], VisaType.ACADEMIC_OR_LANGUAGE_STUDENT_F);
    const sections = useMemo(() => {
        const results: Array<ISection> = [];
        Object.values(FormPage).forEach((pageKey) => {
            const value: ISection = {
                label: formPageLabels[pageKey],
                formPage: pageKey,
            };

            if (pageKey === FormPage.STUDENT_VISA_PAGE1 && isFVisa === false) {
                return;
            }
            if (pageKey === FormPage.STUDENT_VISA_PAGE2 && isFVisa === false) {
                return;
            }
            results.push(value);
        });
        return results;
    }, [isFVisa]);

    if (form.isLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-4xl">
                        <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{label} Completed DS-160 Form</h1>
                        <p className="mt-2 text-sm text-gray-700 mb-4">{description}</p>
                        <TelemetryLink href={url} target="_blank">
                            Download this sample DS-160 PDF.
                        </TelemetryLink>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {sections.map((section) => (
                                <Disclosure key={section.formPage} as="div" className="pt-6">
                                    <dt>
                                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-base/7 font-semibold">{section.label}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                <PlusIcon aria-hidden="true" className="size-6 group-data-[open]:hidden" />
                                                <MinusIcon aria-hidden="true" className="size-6 group-[&:not([data-open])]:hidden" />
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                                        <SectionViewer formId={formId} formPage={section.formPage} />
                                    </DisclosurePanel>
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </>
    );
};
