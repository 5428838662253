import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { ApplicationShell } from "../../molecules/ApplicationShell/ApplicationShell";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { MySubmissions } from "./MySubmissions/MySubmissions";

export const SubmissionsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("dashboardPage.description");
    const title = t("dashboardPage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.Submissions} showNavigation={false}>
            <WithAuth>
                <ApplicationShell>
                    <MySubmissions />
                </ApplicationShell>
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
