import * as React from "react";
import { useForm } from "../../../hooks/api/form/useForm";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { BreadCrumb, IPage } from "../../atoms/BreadCrumb/BreadCrumb";
import { useFormNav } from "../../molecules/FormNavContext/FormNavContext";
import { RedirectTo } from "../../molecules/RedirectTo/RedirectTo";
import { formManifest, formPageJsonPaths, formPageLabels, FormQuestion } from "../FormsPage/Form/manifest/formManifest";
import { Question } from "../FormsPage/Form/Question";
import { FormNavigation } from "./FormNavigation";

interface IProps {}

export const FormEditor: React.FC<React.PropsWithChildren<IProps>> = ({}) => {
    const { formId, formPage } = useFormNav();
    const form = useForm(formId);
    const pages: Array<IPage> = [
        { name: "Forms", href: AppRoute.Forms },
        { name: form.data?.name ?? "", href: AppRoute.FormOverview(formId) },
        { name: formPageLabels[formPage ?? ""] ?? "" },
    ];

    const jsonPath = `$.${formPageJsonPaths[formPage ?? ""]}`;
    const questionList = formManifest[formPage ?? ""].map((question: FormQuestion) => (
        <Question currentJsonPath={jsonPath} key={question.jsonPath} question={question} />
    ));
    const isValidFormPage = form !== undefined;
    if (isValidFormPage === false) {
        return <RedirectTo to={AppRoute.FormOverview(formId)} />;
    }

    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>
            <FormNavigation />
            {questionList}
            <FormNavigation />
        </>
    );
};
