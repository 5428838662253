import { AccountStatus } from "@ds160/library";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPostUserActivateRequestResponseBody, usePostUserActivation } from "../../../hooks/api/user/usePostUserActivation";
import { useAuth } from "../../../hooks/useAuth";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { PrimaryButton } from "../../atoms/Button/PrimaryButton";
import { Spinner } from "../../atoms/Spinner/Spinner";
import { TextInput } from "../../atoms/TextInput/TextInput";
import { LogoSymbol } from "../../molecules/LogoSymbol/LogoSymbol";
import { RedirectTo } from "../../molecules/RedirectTo/RedirectTo";
import { RouterLink } from "../../molecules/RouterLink/RouterLink";
import { SEO } from "../../molecules/SEO/SEO";
import { FormContainer, FormFieldWrapper, Heading, Root } from "./ActivateAccountPage.styles";

export const ActivateAccountPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { tokenData, setAuthToken, logout, isLoading } = useAuth();
    const [t] = useTranslation();
    const [activationCode, setActivationCode] = useState("");

    const onSubmitSuccess = useCallback(
        (response: IPostUserActivateRequestResponseBody) => {
            setAuthToken?.(response.token);
        },
        [setAuthToken]
    );

    const { mutation } = usePostUserActivation({ activationCode }, onSubmitSuccess);

    const onActivationChanged = useCallback(
        (_: unknown, newValue: string | undefined) => {
            const updatedValue = newValue === undefined ? "" : newValue;
            setActivationCode(updatedValue);
        },
        [setActivationCode]
    );

    useEffect(() => {
        if (activationCode.length >= 6) {
            mutation.mutate();
            setActivationCode("");
        }
    }, [activationCode, mutation, setActivationCode]);

    const onLogoutClicked = useCallback(() => {
        logout?.();
    }, [logout]);

    const isComponentLoading = mutation.isLoading === true || isLoading;
    if (isLoading) {
        return <Spinner fontSize="1em" />;
    }
    const activatePlaceholderText = t("activateAccountPage.activationCode");
    if (tokenData === null) {
        return <RedirectTo to={AppRoute.Login} />;
    }
    if (tokenData.status === AccountStatus.Active) {
        return <RedirectTo to={AppRoute.Forms} />;
    }

    return (
        <React.Fragment>
            <SEO
                description={t("activateAccountPage.activate")}
                title={t("activateAccountPage.activate")}
                relativeCanonicalUrl={AppRoute.Activate}
            />
            <Root>
                <RouterLink href={AppRoute.Home}>
                    <LogoSymbol />
                </RouterLink>
                <FormContainer>
                    {isComponentLoading ? (
                        <Spinner fontSize="1em" />
                    ) : (
                        <FormFieldWrapper>
                            <Heading>{t("activateAccountPage.activate")}</Heading>
                            {t("activateAccountPage.instructions")}
                            <TextInput
                                placeholder={activatePlaceholderText}
                                value={activationCode}
                                onChange={onActivationChanged}
                                disabled={isComponentLoading}
                            />
                            <PrimaryButton type="button" onClick={onLogoutClicked}>
                                {t("activateAccountPage.logout")}
                            </PrimaryButton>
                        </FormFieldWrapper>
                    )}
                </FormContainer>
            </Root>
        </React.Fragment>
    );
};
