import { ConsulateCode, SubmissionStatus } from "@ds160/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface ISubmission {
    readonly _id: string;
    readonly projectName: string;
    readonly status: SubmissionStatus;
    /** User who created the document */
    readonly userId: string;
    readonly formId: string;
    readonly created: string;
    readonly consulate: ConsulateCode;
    readonly completedTime?: string | null;
    readonly applicationInfo?: {
        readonly applicationId: string;
        readonly maidenName: string;
    };
    readonly result?:
        | {
              readonly type: "success";
          }
        | {
              readonly type: "failed";
              readonly error: string;
          };
    readonly revision: 1;
}

interface IResponse {
    readonly submission: ISubmission;
}

const getSubmission = async (config: AxiosRequestConfig, submissionId: string) => {
    const uri = `${Constants.ApiRoot}/submission/${submissionId}`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data.submission;
};

export const useSubmission = (submissionId: string) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Submissions, submissionId], () => getSubmission(config, submissionId), {
        refetchInterval: 3000,
    });
};
