import { PageProps } from "gatsby";
import * as React from "react";
import { FormNavProvider } from "../../../../components/molecules/FormNavContext/FormNavContext";
import { EditFormPage } from "../../../../components/pages/EditFormPage/EditFormPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const formId = params?.formId;
    const formPage = params?.formPage;
    return (
        <FormNavProvider formId={formId} formPage={formPage}>
            <EditFormPage />;
        </FormNavProvider>
    );
};
export default Page;
