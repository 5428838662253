import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { CheckList } from "./CheckList";

export const DS160ChecklistPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("ds160ChecklistPage.title");
    const description = t("ds160ChecklistPage.description");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.DS160Checklist}>
            <CheckList />
        </ScrollablePageTemplate>
    );
};
