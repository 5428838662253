import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { CheckoutHero } from "./CheckoutHero";
import { PaypalProvider } from "./PaypalProvider/PaypalProvider";

export const CheckoutPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("pricingPage.title");
    const description = t("pricingPage.description");
    return (
        <PaypalProvider>
            <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.Home}>
                <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
                <CheckoutHero />
            </ScrollablePageTemplate>
        </PaypalProvider>
    );
};
