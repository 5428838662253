import { AppRoute } from "../../../services/Constants/AppRoute";
import { FormPage, formPageLabels } from "../FormsPage/Form/manifest/formManifest";
import { ISection } from "./FormSectionList";

export const getFormSections = (formId: string, isFVisa: boolean): Array<ISection> => {
    const results: Array<ISection> = [];
    Object.values(FormPage).forEach((pageKey) => {
        const value: ISection = {
            label: formPageLabels[pageKey],
            href: AppRoute.EditForm(formId, pageKey),
        };

        if (pageKey === FormPage.STUDENT_VISA_PAGE1 && !isFVisa) {
            return;
        }
        if (pageKey === FormPage.STUDENT_VISA_PAGE2 && !isFVisa) {
            return;
        }
        results.push(value);
    });
    return results;
};
