import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from "@heroicons/react/20/solid";
import * as React from "react";
const features = [
    {
        name: "Real-time Auto-Save.",
        description:
            "Every response is saved instantly, ensuring you never lose progress—even in sections like travel or work history that require detailed information.",
        icon: CloudArrowUpIcon,
    },
    {
        name: "End-to-End Security.",
        description: "Your data is encrypted and protected at every step, giving you peace of mind while completing your application.",
        icon: LockClosedIcon,
    },
    {
        name: "Smart Form Reuse.",
        description:
            "Save time by reusing your previous DS-160 applications for future submissions. Add only the new travel or job history and let the tool handle the rest.",
        icon: ServerIcon,
    },
];

export const WorkflowFeature: React.FC = () => {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base/7 font-semibold text-indigo-600">Stay on Track</h2>
                            <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                Effortless Form Filling
                            </p>
                            <p className="mt-6 text-lg/8 text-gray-600">
                                DS160.io streamlines the DS-160 form-filling process by automatically saving every response as you go.
                                Whether you’re adding travel history, work details, or personal information, you can rest assured that your
                                progress is never lost.
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon aria-hidden="true" className="absolute left-1 top-1 size-5 text-indigo-600" />
                                            {feature.name}
                                        </dt>{" "}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <div className="flex items-start justify-end lg:order-first">
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            width={2432}
                            height={1442}
                            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        >
                            <source src="./demo-form.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};
