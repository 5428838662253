import { ArrowPathIcon, ClockIcon, CubeTransparentIcon } from "@heroicons/react/20/solid";
import * as React from "react";

interface IFeature {
    readonly name: string;
    readonly description: string;
    readonly icon: React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
            title?: string;
            titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
    >;
}
const features: Array<IFeature> = [
    {
        name: "Time savings.",
        description: "We save each of our customers on average 3 hours per DS160 form.",
        icon: ClockIcon,
    },
    {
        name: "The last DS160 you will ever complete.",
        description: "Reuse your previous responses to complete future applications in seconds.",
        icon: ArrowPathIcon,
    },
    {
        name: "Any visa",
        description: "We support E3, H1B, F1 and more.",
        icon: CubeTransparentIcon,
    },
];

export const Features: React.FC = () => {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-base/7 font-semibold text-indigo-600">Introducing DS160 Form Pilot</h2>
                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-5xl">
                        See it in action
                    </p>
                    <p className="mt-6 text-lg/8 text-gray-600">
                        Once you filled out your form, DS160 Form Pilot will fill out your form in a matter of seconds.
                    </p>
                </div>
            </div>
            <div className="relative overflow-hidden pt-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        width={2432}
                        height={1442}
                        className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                    >
                        <source src="./demo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div aria-hidden="true" className="relative">
                        <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
                    </div>
                </div>
            </div>
            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
                <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <feature.icon aria-hidden="true" className="absolute left-1 top-1 size-5 text-indigo-600" />
                                {feature.name}
                            </dt>{" "}
                            <dd className="block">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
};
