import { CheckCircleIcon } from "@heroicons/react/20/solid";
import * as React from "react";

interface IProps {
    readonly amount: number | null;
}
export const DiscountBanner: React.FC<IProps> = ({ amount }) => {
    if (amount === null) {
        return null;
    }
    return (
        <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
                <div className="shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">A discount of ${amount * 100}% has been applied to your order.</p>
                </div>
            </div>
        </div>
    );
};
