import { getPaypalOrderPayload, TelemetryEvent } from "@ds160/library";
import { DISPATCH_ACTION, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { navigate } from "gatsby";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useCreateOrder } from "../../../../hooks/api/order/useCreateOrder";
import { usePostLinkOrder } from "../../../../hooks/api/order/usePostLinkOrder";
import { useAuth } from "../../../../hooks/useAuth";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { AppRoute } from "../../../../services/Constants/AppRoute";
import { SessionStorageKey } from "../../../../services/Constants/SessionStorageKey";
import { telemetry } from "../../../../services/Telemetry/Telemetry";
import { RedirectTo } from "../../../molecules/RedirectTo/RedirectTo";

interface IProps {
    readonly quantity: number;
    readonly pricePerUnit: number;
    readonly subtotalCentsWithDiscount: number;
    readonly couponCode: string;
}

export const PaypalPurchase: React.FC<React.PropsWithChildren<IProps>> = ({
    quantity,
    pricePerUnit,
    couponCode,
    subtotalCentsWithDiscount,
}) => {
    const { authToken, isValid } = useAuth();
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const [orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);

    const onOrderSuccessfullyLinked = async () => {
        await telemetry.Log(TelemetryEvent.SuccessfulConversion, { quantity, conversionValue: pricePerUnit * quantity, currency: "USD" });
    };
    const linkOrder = usePostLinkOrder(onOrderSuccessfullyLinked);
    const { mutation: createOrderMutation } = useCreateOrder();

    useEffect(() => {
        dispatch({
            type: DISPATCH_ACTION.RESET_OPTIONS,
            value: {
                ...options,
                intent: "capture",
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onApprove = useCallback(
        async (data: any, _actions: any) => {
            const payload = getPaypalOrderPayload(data.orderID, quantity);
            const payloadString = JSON.stringify(payload);
            await telemetry.LogConversion(data.orderID ?? "", subtotalCentsWithDiscount);
            if (isValid && authToken !== null) {
                await linkOrder.mutation.mutateAsync(payload);
                navigate(AppRoute.Forms);
            } else {
                setOrderDetails(payloadString);
                navigate(AppRoute.Signup);
            }
        },
        [authToken, isValid, linkOrder.mutation, quantity, setOrderDetails, subtotalCentsWithDiscount]
    );

    const onCreateOrder = useCallback(
        async (_data: any, _actions: any) => {
            const result = await createOrderMutation.mutateAsync({ token: authToken, quantity, couponCode });
            return result.id;
        },
        [authToken, couponCode, createOrderMutation, quantity]
    );

    const paypalButtonKey = React.useMemo(() => `${quantity}-${couponCode}`, [quantity, couponCode]);

    if (authToken === null && orderDetails !== null) {
        return <RedirectTo to={AppRoute.Signup} />;
    }
    if (authToken !== null && orderDetails !== null) {
        return null;
    }

    return (
        <>
            <PayPalButtons
                key={paypalButtonKey}
                createOrder={onCreateOrder}
                onApprove={onApprove}
                style={{
                    label: "buynow",
                    layout: "vertical",
                    tagline: false,
                }}
            />
        </>
    );
};
