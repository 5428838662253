import { CouponType, TelemetryEvent } from "@ds160/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertType, AlertsContext, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IGetProductCouponResponse {
    readonly type: CouponType;
    readonly amount: number;
}

const fetchProductCoupon = async (code: string): Promise<IGetProductCouponResponse | null> => {
    try {
        const config = getAxiosRequestConfig(null);
        const { data } = await axios.get(`${Constants.ApiRoot}/product/coupon/${code}`, config);
        return data;
    } catch (error) {
        const e = error as AxiosError;
        if (e.response?.status === 404) {
            return null;
        }
        throw e;
    }
};

export const useProductCoupon = (couponCode: string, onSuccess: (response: IGetProductCouponResponse | null) => void) => {
    const { pushAlert } = useContext(AlertsContext);

    const query = useQuery([QueryCacheKey.Coupon, couponCode], () => fetchProductCoupon(couponCode), {
        enabled: couponCode.length > 0,
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.ValidateCouponSuccess, null);
            onSuccess(data);
        },
        onError: (error) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.ValidateCouponError, submitError);
        },
        onSettled: () => {
            telemetry.Log(TelemetryEvent.ValidateCouponInit, null);
        },
    });

    return query;
};
