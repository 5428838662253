import { SampleFormInputKey } from "@ds160/library";
import { Link } from "gatsby";
import * as React from "react";
import { AppRoute } from "../../../services/Constants/AppRoute";

interface ISample {
    readonly name: string;
    readonly sampleKey: SampleFormInputKey;
}
const samples: Array<ISample> = [
    {
        name: "Complex DS-160",
        sampleKey: SampleFormInputKey.Complex,
    },
    {
        name: "Simple DS-160",
        sampleKey: SampleFormInputKey.Simple,
    },
];

export const SampleSelection: React.FC = () => {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold sm:text-5xl">Sample Completed DS-160 US Visa Forms</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Here are free sample completed DS-160 US visa forms to help you with your next DS-160 application.
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                            >
                                                Name
                                            </th>

                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                <span className="sr-only">View</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {samples.map((sample) => (
                                            <tr key={sample.sampleKey}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {sample.name}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                    <Link
                                                        to={AppRoute.ViewSampleForm(sample.sampleKey)}
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        View<span className="sr-only">, {sample.name}</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
