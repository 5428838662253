import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import * as React from "react";

export const PaypalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <PayPalScriptProvider
            options={{
                clientId: process.env.GATSBY_PAYPAL_CLIENT_ID ?? "",
                components: "buttons,applepay,googlepay",
                intent: "capture",
                vault: true,
            }}
        >
            {children}
        </PayPalScriptProvider>
    );
};
