import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export enum AlertType {
    Success = "success",
    Error = "error",
}

export interface IAlert {
    readonly type: AlertType;
    readonly message: string;
    readonly timeout: number;
}

interface IAuthContextProps {
    readonly alerts: Array<IAlert> | null;
    readonly pushAlert: ((alert: IAlert) => void) | null;
}
export const AlertsContext = createContext<IAuthContextProps>({ alerts: null, pushAlert: null });

export const AlertsContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [t] = useTranslation();
    const [activeAlert, setActiveAlert] = useState<IAlert | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const [alerts, setAlerts] = useState<Array<IAlert>>([]);
    const onPushAlert = useCallback(
        (alert: IAlert) => {
            setAlerts([...alerts, alert]);
        },
        [alerts]
    );

    const loadNextAlert = useCallback(() => {
        const nextAlert = alerts.shift() ?? null;
        if (nextAlert === null) {
            setActiveAlert(null);
            setIsVisible(false);
            return;
        }
        setActiveAlert(nextAlert);
        setAlerts([...alerts]);
        setIsVisible(true); // Show the alert
    }, [alerts]);

    useEffect(() => {
        if (activeAlert !== null) {
            const timer = setTimeout(() => {
                setIsVisible(false); // Hide the alert after timeout
            }, activeAlert.timeout);

            return () => {
                clearTimeout(timer);
            };
        }
        loadNextAlert();
    }, [activeAlert, loadNextAlert]);

    return (
        <AlertsContext.Provider value={{ alerts, pushAlert: onPushAlert }}>
            {children}
            <div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        appear={true}
                        show={isVisible}
                        enter="transform transition duration-300 ease-out"
                        enterFrom="translate-y-2 opacity-0"
                        enterTo="translate-y-0 opacity-100"
                        leave="transform transition duration-300 ease-in"
                        leaveFrom="translate-y-0 opacity-100"
                        leaveTo="translate-y-2 opacity-0"
                        afterLeave={loadNextAlert} // Trigger next alert after the current one disappears
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="shrink-0">
                                        {activeAlert?.type === AlertType.Success ? (
                                            <CheckCircleIcon aria-hidden="true" className="size-6 text-green-400" />
                                        ) : (
                                            <ExclamationCircleIcon aria-hidden="true" className="size-6 text-red-400" />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            {activeAlert?.type === AlertType.Success ? t("alertModal.success") : t("alertModal.error")}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">{activeAlert?.message}</p>
                                    </div>
                                    <div className="ml-4 flex shrink-0">
                                        <button
                                            type="button"
                                            onClick={() => setIsVisible(false)} // Manually dismiss alert
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="size-5" />
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-4 h-1 w-full bg-gray-200">
                                    <div
                                        className="h-full bg-indigo-500 animate-shrink"
                                        style={
                                            { "--animation-duration": `${(activeAlert?.timeout ?? 1000) / 1000}s` } as React.CSSProperties
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </AlertsContext.Provider>
    );
};
