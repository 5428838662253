import { ConsulateCode, consulateMap } from "@ds160/library";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useCallback, useState } from "react";

const consulateCodes: ReadonlyArray<ConsulateCode> = Object.keys(consulateMap) as ReadonlyArray<ConsulateCode>;

interface IProps {
    readonly onChange: (value: ConsulateCode | null) => void;
}

export const ConsulateSelection: React.FC<IProps> = ({ onChange }) => {
    const [query, setQuery] = useState<string>("");
    const [selected, setSelected] = useState<ConsulateCode | null>(null);

    const filteredConsulates =
        query === "" ? consulateCodes : consulateCodes.filter((code) => consulateMap[code].toLowerCase().includes(query.toLowerCase()));

    const onConsulateCodeChanged = useCallback(
        (value: ConsulateCode | null) => {
            setQuery("");
            setSelected(value);
            onChange(value);
        },
        [onChange]
    );

    const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    }, []);

    const onClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
        event.currentTarget.select();
    }, []);

    return (
        <Combobox as="div" value={selected} onChange={onConsulateCodeChanged}>
            <Label className="block text-sm/6 font-medium text-gray-900">Consulate</Label>
            <div className="relative mt-2">
                <ComboboxInput
                    className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(code: ConsulateCode) => consulateMap[code] ?? "SELECT A CONSULATE"}
                    onFocus={onFocus}
                    onClick={onClick}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                {filteredConsulates.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {filteredConsulates.map((code) => (
                            <ComboboxOption
                                key={code}
                                value={code}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                            >
                                <span className="block truncate font-normal group-data-[selected]:font-semibold">{consulateMap[code]}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon className="size-5" aria-hidden="true" />
                                </span>
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    );
};
