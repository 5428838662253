import { graphql, PageProps } from "gatsby";
import { getImage, ImageDataLike, getSrc } from "gatsby-plugin-image";
import { Root } from "hast";
import React from "react";
import rehypeReact from "rehype-react";
import {
    RemarkA,
    RemarkBlockquote,
    RemarkCode,
    RemarkH1,
    RemarkH2,
    RemarkH3,
    RemarkH4,
    RemarkH5,
    RemarkH6,
    RemarkHr,
    RemarkImg,
    RemarkLi,
    RemarkOl,
    RemarkP,
    RemarkPre,
    RemarkTable,
    RemarkTd,
    RemarkTh,
    RemarkTr,
    RemarkUl,
} from "../../atoms/RemarkComponents/RemarkComponents";
import { IRemarkInternalLinkProps, RemarkInternalLink } from "../../atoms/RemarkComponents/RemarkInternalLink";
import { ScrollablePageTemplate } from "../ScrollablePageTemplate/ScrollablePageTemplate";

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            internallink: React.PropsWithChildren<IRemarkInternalLinkProps>;
        }
    }
}

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        h1: RemarkH1,
        h2: RemarkH2,
        h3: RemarkH3,
        h4: RemarkH4,
        h5: RemarkH5,
        h6: RemarkH6,
        p: RemarkP,
        blockquote: RemarkBlockquote,
        code: RemarkCode,
        pre: RemarkPre,
        ul: RemarkUl,
        ol: RemarkOl,
        li: RemarkLi,
        a: RemarkA,
        img: RemarkImg,
        hr: RemarkHr,
        table: RemarkTable,
        th: RemarkTh,
        td: RemarkTd,
        tr: RemarkTr,
        internallink: RemarkInternalLink,
    },
}).Compiler;

interface Frontmatter {
    readonly title: string;
    readonly slug: string;
    readonly description: string;
    readonly featuredImage: ImageDataLike;
    readonly featuredImageAlt: string;
}

interface MarkdownRemark {
    frontmatter: Frontmatter;
    html: string;
    htmlAst: Root;
}

interface DataProps {
    markdownRemark: MarkdownRemark;
}

const BlogPostTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
    const { markdownRemark } = data;
    const { frontmatter, htmlAst } = markdownRemark;

    const title = frontmatter.title;
    const description = frontmatter.description;
    const canonical = frontmatter.slug;
    const featuredAlt = frontmatter.featuredImageAlt;
    const image = getImage(frontmatter.featuredImage);
    const featuredImageSrc = `https://${process.env.GATSBY_HOST}${getSrc(image)}`;

    return (
        <ScrollablePageTemplate title={title} description={description} canonical={canonical} featuredImage={featuredImageSrc}>
            <div className="py-10">
                <header>
                    <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title}</h1>
                        {image && <img src={featuredImageSrc} alt={featuredAlt} className="mt-4 w-full rounded-lg" />}
                    </div>
                </header>
                <main>
                    <div className="mx-auto max-w-3xl px-4 py-8 sm:px-6 lg:px-8 prose">
                        <article>{renderAst(htmlAst)}</article>
                    </div>
                </main>
            </div>
        </ScrollablePageTemplate>
    );
};

export const query = graphql`
    query BlogPostQuery($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            htmlAst
            frontmatter {
                title
                slug
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                    }
                }
                featuredImageAlt
            }
        }
    }
`;

export default BlogPostTemplate;
