import { FormStatus } from "@ds160/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IForm {
    readonly _id: string;
    readonly name: string;
    readonly status: FormStatus;
    readonly created: string;
    readonly revision: number;
}

interface IResponse {
    readonly forms: Array<IForm>;
}

const getForms = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/form/list`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data.forms;
};

export const useFormsList = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Forms], () => getForms(config));
};
