import { HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";
import * as React from "react";
import { AppRoute } from "../../../services/Constants/AppRoute";

export type IPage = {
    readonly name: string;
    readonly href?: string;
};

interface IProps {
    readonly pages: Array<IPage>;
}

export const BreadCrumb: React.FC<IProps> = ({ pages }) => {
    const crumbItems = pages.map((page) => {
        const linkComponent =
            page.href !== undefined ? (
                <Link to={page.href} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {page.name}
                </Link>
            ) : (
                <span className="ml-4 text-sm font-medium text-gray-500 select-none" aria-current="page">
                    {page.name}
                </span>
            );
        return (
            <li key={page.name}>
                <div className="flex items-center">
                    <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="size-5 shrink-0 text-gray-300">
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    {linkComponent}
                </div>
            </li>
        );
    });

    return (
        <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to={AppRoute.Home} className="text-gray-400 hover:text-gray-500">
                            <HomeIcon aria-hidden="true" className="size-5 shrink-0" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {crumbItems}
            </ol>
        </nav>
    );
};
