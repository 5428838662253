import { ConsulateCode } from "@ds160/library";
import { PlusIcon } from "@heroicons/react/20/solid";
import { navigate } from "gatsby";
import * as React from "react";
import { useCallback, useState } from "react";
import { useCreateSubmission } from "../../../../hooks/api/submission/useCreateSubmission";
import { AppRoute } from "../../../../services/Constants/AppRoute";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { useFormNav } from "../../../molecules/FormNavContext/FormNavContext";
import { ConsulateSelection } from "./ConsulateSelection";

interface IProps {}

export const NewSubmission: React.FC<IProps> = ({}) => {
    const { formId } = useFormNav();
    const onCreateSuccess = useCallback((response: { submissionId: string }) => {
        const submissionId = response.submissionId;
        navigate(AppRoute.EditSubmission(submissionId));
    }, []);
    const [consulateCode, setConsulateCode] = useState<ConsulateCode | null>(null);

    const createApplication = useCreateSubmission(formId, onCreateSuccess);

    const onNewClicked = React.useCallback(() => {
        if (consulateCode === null) {
            return;
        }
        createApplication.mutation.mutate(consulateCode);
    }, [consulateCode, createApplication.mutation]);

    const onConsulateCodeChanged = useCallback((code: ConsulateCode | null) => {
        setConsulateCode(code);
    }, []);

    if (createApplication.mutation.isLoading) {
        return <Spinner />;
    }

    const isSubmitDisabled = consulateCode === null;
    return (
        <>
            <form>
                <div className="px-4 sm:px-4 lg:px-6 pt-6">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold text-gray-900">Submit DS160</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Once you have completed all the sections above, create a submission to let our form filler complete your
                                DS-160.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-gray-900/10 pb-2 md:grid-cols-3">
                        <div>
                            <h2 className="text-base/7 font-semibold text-gray-900">Location</h2>
                            <p className="mt-1 text-sm/6 text-gray-600">
                                This is the US embassy or consulate where you plan to apply for your visa.
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-4">
                                <ConsulateSelection onChange={onConsulateCodeChanged} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-right border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <button
                        disabled={isSubmitDisabled}
                        type="button"
                        onClick={onNewClicked}
                        className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm 
                            ${
                                isSubmitDisabled
                                    ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                                    : "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            }`}
                    >
                        <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                        New Submission
                    </button>
                </div>
            </form>
        </>
    );
};
