import { CheckIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useCallback, useState } from "react";
import { useSendCaptcha } from "../../../hooks/api/submission/useSendCaptcha";
import { useSubmissionCaptcha } from "../../../hooks/api/submission/useSubmissionCaptcha";

interface IProps {
    readonly submissionId: string;
}

export const Captcha: React.FC<IProps> = ({ submissionId }) => {
    const [wasCaptchaSolved, setWasCaptchaSolved] = useState(false);
    const captcha = useSubmissionCaptcha(submissionId);
    const [captchaCode, setCaptchaCode] = useState("");

    const sendCaptcha = useSendCaptcha(submissionId, () => {
        setCaptchaCode("");
        setWasCaptchaSolved(true);
    });

    const onCaptchaChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value;
        const upperCaseValue = rawValue.toUpperCase();
        setCaptchaCode(upperCaseValue);
    }, []);
    const onSend = useCallback(() => {
        sendCaptcha.mutation.mutate(captchaCode);
    }, [captchaCode, sendCaptcha.mutation]);

    if (captcha.isLoading || captcha.data === undefined) {
        return null;
    }
    if (wasCaptchaSolved) {
        return null;
    }

    return (
        <div>
            <label htmlFor="captcha" className="block text-sm/6 font-medium text-gray-900">
                You have 5 minutes to solve the captcha before it expires.
            </label>
            <img src={captcha.data.captcha} alt="Captcha" />
            <div className="mt-2">
                <input
                    id="captcha"
                    name="captcha"
                    type="text"
                    value={captchaCode}
                    onChange={onCaptchaChange}
                    placeholder="Captcha code"
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                />
            </div>
            <div className="text-right border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <button
                    type="button"
                    onClick={onSend}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <CheckIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                    Submit Captcha
                </button>
            </div>
        </div>
    );
};
