import { TelemetryEvent } from "@ds160/library";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertType, AlertsContext, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";

interface IResponse {
    readonly status: string;
    readonly id: string;
    readonly create_time: string;
    readonly link: Array<{
        readonly href: string;
        readonly rel: string;
        readonly method: string;
    }>;
}

interface IOptions {
    readonly token: string | null;
    readonly quantity: number;
    readonly couponCode: string;
}

const getMutationQuery = () => {
    const postRequest = async (options: IOptions) => {
        const { token, quantity, couponCode } = options;
        const config = getAxiosRequestConfig(token);
        const payload = { quantity, couponCode };
        const { data } = await axios.post<IResponse>(`${Constants.ApiRoot}/product`, payload, config);

        return data;
    };
    return postRequest;
};

export const useCreateOrder = (onSuccess?: (response: IResponse) => void) => {
    const { pushAlert } = useContext(AlertsContext);

    const mutation = useMutation(getMutationQuery(), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.CreateOrderError, null);
        },
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.CreateOrderSuccess, null);
            onSuccess?.(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.CreateOrderInit, submitError);
        },
    });
    return { mutation };
};
