import { TelemetryEvent } from "@ds160/library";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { useAuth } from "../../useAuth";

interface IResponseBody {}

const getMutationQuery = (token: string | null, submissionId: string) => {
    const postRequest = async (captchaCode: string): Promise<IResponseBody> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.post(`${Constants.ApiRoot}/submission/${submissionId}/captcha`, { captchaCode }, config);

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const useSendCaptcha = (submissionId: string, onSuccess: () => void) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);

    const mutation = useMutation(getMutationQuery(authToken, submissionId), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.SendCaptchaInit, null);
        },
        onSuccess: (_data) => {
            onSuccess();
            telemetry.Log(TelemetryEvent.SendCaptchaSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.SendCaptchaError, submitError);
        },
    });

    return { mutation };
};
