import { useEffect, useRef, useCallback } from "react";

/**
 * A hook to debounce a callback function.
 *
 * @param callback - The function to be debounced.
 * @param delay - The debounce delay in milliseconds.
 * @returns A debounced version of the callback.
 */
export const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay: number): ((...args: Parameters<T>) => void) => {
    const timer = useRef<NodeJS.Timeout | null>(null);

    const debouncedCallback = useCallback(
        (...args: Parameters<T>) => {
            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    return debouncedCallback;
};
