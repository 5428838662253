import { PageProps } from "gatsby";
import * as React from "react";
import { FormNavProvider } from "../../components/molecules/FormNavContext/FormNavContext";
import { FormOverviewPage } from "../../components/pages/FormOverviewPage/FormOverviewPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const formId = params?.formId;
    return (
        <FormNavProvider formId={formId}>
            <FormOverviewPage />;
        </FormNavProvider>
    );
};
export default Page;
