import * as React from "react";

interface ProgressBarProps {
    readonly steps: Array<string>;

    /** The current step index (0-based) */
    readonly currentStep: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStep }) => {
    const totalSteps = steps.length;

    const clampedStep = Math.max(0, Math.min(currentStep, totalSteps - 1));

    const percentage = (clampedStep / (totalSteps - 1)) * 100;

    return (
        <div>
            <h4 className="sr-only">Status</h4>
            <div aria-hidden="true" className="mt-6">
                <div className="overflow-hidden rounded-full bg-gray-200">
                    <div style={{ width: `${percentage}%` }} className="h-2 rounded-full bg-indigo-600" />
                </div>
                <div className="mt-6 hidden grid-cols-5 text-sm font-medium text-gray-600 sm:grid">
                    {steps.map((step, index) => (
                        <div
                            key={step}
                            className={`text-center ${
                                index === clampedStep ? "text-indigo-600 font-medium" : index < clampedStep ? "text-indigo-600" : ""
                            }`}
                        >
                            {step}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
