import * as React from "react";

// Define the checklist item type
interface IChecklistItem {
    readonly id: string;
    readonly name: string;
    readonly description: string;
}

const checklistItems: Array<IChecklistItem> = [
    {
        id: "passport",
        name: "Passport",
        description: "You should have your passport available while completing your DS-160.",
    },
    {
        id: "travelItinerary",
        name: "Travel Itinerary",
        description: "If you have already made travel arrangements, you should have your travel itinerary available.",
    },
    {
        id: "travelHistory",
        name: "Travel History",
        description:
            "Dates of your last five visits or trips to the United States, if applicable, and international travel history for the past five years.",
    },
    {
        id: "resume",
        name: "Résumé or Curriculum Vitae",
        description: "Information about your current and previous education and work history may be required.",
    },
    {
        id: "otherInformation",
        name: "Other Information",
        description: "Some applicants may be asked to provide additional information depending on the intended purpose of travel.",
    },
    {
        id: "studentsExchangeVisitors",
        name: "Students and Exchange Visitors (F, J, and M)",
        description:
            "Provide your SEVIS ID from your I-20 or DS-2019, as well as the address of the school/program at which you intend to study.",
    },
    {
        id: "petitionTemporaryWorkers",
        name: "Petition-based Temporary Workers",
        description: "A copy of your I-129 form is required for H-1B, H-2, H-3, CW1, L, O, P, R, and E2C visa categories.",
    },
    {
        id: "otherTemporaryWorkers",
        name: "Other Temporary Workers",
        description: "You will need information about your employer, including the employer’s address.",
    },
];

export const CheckList: React.FC = () => {
    return (
        <div>
            <div className="py-10">
                <header>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                            Checklist of items you will need to have to complete your DS-160 US visa form.
                        </h1>
                    </div>
                </header>
                <main>
                    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                        <fieldset className="border-b border-t border-gray-200">
                            <legend className="sr-only">Notifications</legend>
                            <div className="divide-y divide-gray-200">
                                {checklistItems.map((item) => (
                                    <div key={item.id} className="relative flex gap-3 pb-4 pt-3.5">
                                        <div className="min-w-0 flex-1 text-sm/6">
                                            <label htmlFor={item.id} className="font-medium text-gray-900">
                                                {item.name}
                                            </label>
                                            <p id={`${item.id}-description`} className="text-gray-500">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="flex h-6 shrink-0 items-center">
                                            <div className="group grid size-4 grid-cols-1">
                                                <input
                                                    id={item.id}
                                                    name={item.id}
                                                    type="checkbox"
                                                    aria-describedby={`${item.id}-description`}
                                                    className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                                />
                                                <svg
                                                    fill="none"
                                                    viewBox="0 0 14 14"
                                                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                                                >
                                                    <path
                                                        d="M3 8L6 11L11 3.5"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="opacity-0 group-has-[:checked]:opacity-100"
                                                    />
                                                    <path
                                                        d="M3 7H11"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    </div>
                </main>
            </div>
        </div>
    );
};
