import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useSubmission } from "../../../hooks/api/submission/useSubmission";
import { Loading } from "../../atoms/Loading/Loading";

interface IStatusField {
    readonly fieldName: string;
    readonly data: string;
}

interface IProps {
    readonly submissionId: string;
}

export const Status: React.FC<IProps> = ({ submissionId }) => {
    const submission = useSubmission(submissionId);

    if (submission.isLoading || submission.data === undefined) {
        return <Loading />;
    }
    const applicationId = submission.data.applicationInfo?.applicationId;
    const maidenName = submission.data.applicationInfo?.maidenName;
    const consulateCode = submission.data.consulate;

    const stats: Array<IStatusField> = [
        { fieldName: "Consulate", data: consulateCode },
        { fieldName: "ApplicationId", data: applicationId ?? "N/A" },
        { fieldName: "Mother's maiden name", data: maidenName ?? "N/A" },
    ];

    return (
        <div className="mt-10 space-y-8">
            <h3 className="text-lg font-semibold text-gray-800">Submission Details</h3>
            <dl className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                {stats.map((item) => (
                    <div key={item.fieldName} className="overflow-hidden rounded-lg bg-gray-50 px-6 py-5 shadow-md border border-gray-200">
                        <dt className="truncate text-sm font-medium text-gray-600">{item.fieldName}</dt>
                        <dd className="mt-2 text-2xl font-bold text-gray-800">{item.data}</dd>
                    </div>
                ))}
            </dl>
            {applicationId && (
                <div className="flex justify-end mt-4">
                    <a
                        href="https://ceac.state.gov/GenNIV/Default.aspx"
                        className="inline-flex items-center bg-indigo-600 text-white shadow hover:bg-indigo-500 mt-8 rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ArrowTopRightOnSquareIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                        Retrieve DS160 Form
                    </a>
                </div>
            )}
        </div>
    );
};
