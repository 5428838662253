import * as React from "react";
import { ScrollableBodyRoot } from "../../layout/BodyRoot/BodyRoot";
import { Footer } from "../../molecules/Footer/Footer";
import { NavigationBar } from "../../molecules/NavigationHeader/NavigationBar";
import { SEO } from "../../molecules/SEO/SEO";

interface IProps {
    readonly title: string;
    readonly description: string;
    readonly canonical: string;
    readonly showNavigation?: boolean;
    readonly featuredImage?: string;
}

export const ScrollablePageTemplate: React.FC<React.PropsWithChildren<IProps>> = ({
    children,
    title,
    description,
    canonical,
    showNavigation = true,
    featuredImage,
}) => {
    return (
        <React.Fragment>
            <SEO title={title} description={description} relativeCanonicalUrl={canonical} socialImage={featuredImage} />
            <ScrollableBodyRoot showNavigation={showNavigation}>{children}</ScrollableBodyRoot>
            {showNavigation && <NavigationBar />}
            {showNavigation && <Footer />}
        </React.Fragment>
    );
};
